import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import { type GamePack } from '../../types/game';
import { PurchaseMethod } from './type';

const defaultDiscount = 0.1;

export class OTPUtils {
  static ParseHeadcount(param: Nullable<string>) {
    if (!param) return 0;
    const parsed = parseInt(param);
    if (isNaN(parsed)) return 0;
    return parsed;
  }
  static ParsePurchaseMethod(param: Nullable<string>): PurchaseMethod | null {
    if (Object.values(PurchaseMethod).includes(param as PurchaseMethod)) {
      return param as PurchaseMethod;
    }
    return null;
  }
  static CalculateDiscount(
    amountInDollars: number,
    discount = defaultDiscount
  ): number {
    const amountInCents = Math.round(amountInDollars * 100);
    const discountedAmountInCents = Math.round(amountInCents * (1 - discount));
    return discountedAmountInCents / 100;
  }

  static CalculateDiscountAmount(
    amountInDollars: number,
    discount = defaultDiscount
  ): number {
    const amountInCents = Math.round(amountInDollars * 100);
    const discountAmountInCents = Math.round(amountInCents * discount);
    return discountAmountInCents / 100;
  }
  static GamePackType(pack: GamePack | DtoGamePack) {
    if (pack.cohostSettings?.enabled) {
      return 'cohosted';
    }
    return 'on-demand';
  }
}
